@import "../../sass/utils/variable.scss";
@import "../../sass/utils/function.scss";

.field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  * {
    width: 100%;
  }

  &__label {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: (15px);
    font-weight: 600;
  }

  &__input {
  }
}
