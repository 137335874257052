@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.nav {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  backdrop-filter: blur(60px);
  z-index: 100;
  @extend %transparency;

  @media screen and (max-width: map-get($size, medium)) {
    display: none;
  }

  &__parent {
    @include box-shadow(7px);
    padding: 20px 0 0 15px;
    height: 100%;
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 22;

    &__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      position: absolute;
      top: -55px;
      left: -0px;

      svg {
        width: 60%;
        fill: map-get($color, secondary);
      }
    }

    &__contain {
      margin-top: 80px;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__child {
    position: relative;
    height: 100vh;
    width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @include box-shadow(7px);

    &__contain {
      overflow: hidden;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &__toggle {
        height: 40px;
        background-color: map-get($color, action);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &__icon {
          font-weight: bold;
          font-size: size(18px);
          color: map-get($color, primary);
        }
      }

      &__menu {
        padding: 0 0 0 10px;
      }
    }

    &__open {
      cursor: pointer;
      position: absolute;
      right: 0px;
      transform: translateX(100%);
      height: 70px;
      background-color: map-get($color, action);
      width: 14px;
      border-radius: 0 5px 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      pointer-events: 'none';

      &__line {
        background-color: map-get($color, secondary);
        height: 80%;
        width: 2px;
      }

      * {
        pointer-events: none;
      }
    }
  }
}
