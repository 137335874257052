@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.menu {
  padding-left: 5px;
  width: 100%;
  margin-bottom: 30px;
  background-color: transparent !important;

  * {
    background-color: transparent !important;
  }

  &.ant-menu {
    border-inline-end: 1px solid transparent !important;
  }

  .ant-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px 10px 5px !important;
    margin: 0px !important;
    padding-inline: 0px !important;
    width: 100% !important;
    border-radius: 0px;
    overflow: visible;
    height: initial !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .ant-menu-item-selected {
    background-color: transparent;

    &::after {
      content: '';
      z-index: 2;
      position: absolute;
      right: -1px;
      height: 90%;
      width: 3px;
    }
  }

  .ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: size(11px);
    font-weight: 600;
    padding: 10px 10px 10px 0px;
  }

  .ant-menu-title-content {
    font-size: size(16px);
    font-weight: bold;
    line-height: 1 !important;
  }

  .ant-menu-item-icon svg {
    font-size: size(18px);
  }
}
