@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.timeline {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
  width: 100%;
  height: 100%;
  @include scrollbar('component');

  .ant-timeline {
    width: 90% !important;
  }
}
