@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.widget {
  overflow: hidden;
  position: relative;
  margin: 10px;
  width: initial;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  // flex: 1;
  width: 100%;
  box-sizing: border-box;
  max-width: calc(100% - 20px);
  min-width: 500px;
  height: 100%;
  @extend %transparency;
  border-radius: 3px;
  @include box-shadow(4px);
  padding: 13px;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media screen and (max-width: 700px) {
    min-width: calc(map-get($size, min) - 20px);
  }

  @media screen and (max-width: map-get($size, medium)) {
    padding: 10px;
  }

  &__title {
    display: inline-block;
    font-size: size(16px);
    font-weight: 600;
    line-height: 1;
    padding-bottom: 15px;
    width: 100%;
    color: map-get($color, secondary);
    z-index: 2;
  }

  &__contain {
    // backdrop-filter: blur(100px);
    z-index: 2;
    width: 100%;
  }
}
