$mode: 'dark';

$primary: if($mode == 'light', white, black);
$secondary: if($mode == 'light', black, white);

@function transformColor($color, $amount) {
  @if ($mode == 'light') {
    @return rgba($color, 1 - $amount / 100);
  } @else {
    @return rgba($color, 1 - $amount / 100);
  }
}

$color: (
  primary: $primary,
  secondary: $secondary,
  gray0: gray,
  action: #3cb2c9,
  info: #e0ca3c,
  error: #ff4d4f,
  warn: #faad14,
  success: #52c41a,
  graph1: #f72585,
  graph2: #7209b7,
  graph3: #008000,
  graph4: #f86624,
);

:root {
  @each $name, $item in $color {
    --color-#{$name}: #{$item};
  }
}

$size: (
  min: 350px,
  small: 500px,
  medium: 1000px,
  max: 2000px,
);

:root {
  --min-size: 350px;
  --small-size: 500px;
  --medium-size: 1000px;
  --max-size: 2000px;
}
