@import '../../../sass/utils/variable.scss';
@import '../../../sass/utils/function.scss';
@import '../../../sass/utils/mixin.scss';

.login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__present {
    width: 100%;
    flex: 1;
    background-color: map-get($color, action);
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    @include box-shadow(15px);

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    video {
      height: 100vh;
    }

    &__logo {
      height: 100%;
      width: 100%;
      z-index: 3;
      position: absolute;
      inset: 0 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        @include box-shadow(10px);
        backdrop-filter: blur(30px);
        height: 40vh;
      }
    }
  }

  &__form {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__contain {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 70%;

      &__title {
        font-size: size(55px);
        font-weight: bold;
        margin-bottom: 50px;
      }

      &__line {
        width: 100%;
        margin-bottom: 30px;

        .field__input {
          height: 40px;
        }

        &:last-of-type {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0px;

          .button {
            height: 50px;
            width: 50%;
            font-size: size(18px);
          }
        }
      }
    }
  }
}
