@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.badge {
  &.badge-text-yes {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    @include box-shadow(4px);

    span {
      padding: 5px;
      color: map-get($color, primary);
      text-transform: uppercase;
      font-size: size(10px);
      font-weight: bold;
    }
  }

  &.badge-text-no {
    aspect-ratio: 1;
    height: 12px;
    border-radius: 100%;
  }
}
