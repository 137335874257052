@import './variable.scss';

@mixin box-shadow($blur, $container: 'initial') {
  @if ($container == 'inset') {
    box-shadow: inset 0px 0px $blur map-get($color, primary);
  } @else {
    box-shadow: 0px 0px $blur map-get($color, primary);
  }
}

@mixin scrollbar($type) {
  $scroll-color: red;
  $scroll-width: 3px;
  $scroll-radius: 0px;

  @if ($type == 'component') {
    $scroll-color: map-get($color, gray0);
    $scroll-width: 2px;
    $scroll-radius: 15px;
  }

  @if ($type == 'page') {
    $scroll-color: map-get($color, secondary);
  }

  //* Firefox
  scrollbar-color: transparent $scroll-color;
  scrollbar-width: thin;

  //* Autres
  &::-webkit-scrollbar {
    width: $scroll-width !important;
    position: absolute !important;
    mix-blend-mode: exclusion !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-color !important;
    border-radius: $scroll-radius;
  }
}
