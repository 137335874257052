@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.main {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__part:first-of-type {
    z-index: 2;
  }

  &__part:last-of-type {
    width: 100%;
    z-index: 1;
  }
}
