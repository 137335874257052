@import './variable.scss';
@import './function.scss';
@import './mixin.scss';

%label {
  color: map-get($color, gray5);
  font-size: size(13px);
  // text-transform: uppercase;
  font-weight: 600;
}

%transparency {
  background-color: rgba(map-get($color, secondary), 0.06);
}
