@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 5px 5px 10px 15px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
  position: relative;
  @include scrollbar('page');

  @media screen and (max-width: map-get($size, medium)) {
    padding: 10px 5px;
  }

  &__header {
    padding: 20px 10px;
    width: 100%;

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &__name {
        font-size: size(27px);
        font-weight: 600;
        margin-bottom: 3px;
        color: map-get($color, secondary);
      }

      &__job {
        font-size: size(14px);
        font-weight: 300;
        color: map-get($color, secondary);
      }
    }

    &__more {
    }
  }

  &__nav {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    right: -6vw;
    z-index: 99999999;
    width: 100%;
    pointer-events: none;

    &__contain {
      display: flex;
      justify-content: center;
      align-items: center;
      @include box-shadow(10px);
      padding: 7px;
      border-radius: 5px;
      pointer-events: visible;
      background-color: rgba(map-get($color, primary), 0.95);

      * {
        pointer-events: visible;
        // color: map-get($color, primary);
      }

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        cursor: pointer;
        width: 70px;
        overflow: hidden;
        color: map-get($color, secondary);

        &.selected * {
          color: map-get($color, action);
        }

        &:first-of-type {
          margin-left: 0px;
        }

        &:last-of-type {
          margin-right: 0px;
        }

        * {
          pointer-events: none;
          transition: all 0.3 ease-in-out;
        }

        &:hover * {
          color: map-get($color, action);
        }

        svg {
          font-size: size(18px);
        }

        &__text {
          margin-top: 4px;
          font-size: size(12px);
        }
      }
    }
  }

  &__contain {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
  }
}
