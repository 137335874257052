@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';
@import '../../sass/utils/placeholder.scss';

.table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &__contain {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    @include scrollbar('component');

    * {
      background-color: transparent !important;
      border-radius: 0px !important;
    }

    &.table-empty table,
    &.table-empty .ant-table-wrapper,
    &.table-empty .ant-table-container,
    &.table-empty .ant-table-content,
    &.table-empty .ant-table,
    &.table-empty .ant-table-tbody,
    &.table-empty .ant-table-placeholder {
      height: 100% !important;
    }

    .ant-table-thead {
      inset: 0 0 0 0;
      z-index: 20;
      width: 100%;
      overflow: visible;
    }

    .ant-table-thead .ant-table-cell {
      @extend %label;
      // color: map-get($color, primary);
    }

    .ant-table-row .ant-table-cell,
    .ant-table-thead .ant-table-cell {
      padding-left: 18px !important;
    }

    .ant-table-row .ant-table-cell:last-of-type,
    .ant-table-thead .ant-table-cell:last-of-type {
      padding-right: 18px !important;
    }

    .ant-table-tbody .ant-table-cell {
      font-size: size(14px);
    }

    .ant-table-cell-row-hover {
      cursor: pointer;
    }

    .ant-table-expanded-row .ant-table-cell {
      // @include box-shadow(2px, inset);
      padding: 10px 18px 0px 18px !important;
    }

    .ant-table-thead .ant-table-cell::first-letter {
      text-transform: capitalize !important;
    }
  }

  &__page {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding: 15px 18px 0px 15px;

    .ant-pagination {
      margin: 0 !important;
      display: flex;
    }
    .ant-pagination-item,
    [class^='ant-pagination-jump'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
