@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

.list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__contain {
    width: 100%;
    overflow-y: scroll;
    @include scrollbar('component');

    .ant-list-item {
      padding: 9px 0 !important;
      overflow: hidden !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;

      &:first-of-type {
        padding-top: 0px !important;
      }

      &:last-of-type {
        padding-bottom: 0px !important;
      }
    }

    .ant-list-item-meta {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      align-content: center !important;
      margin: 0 0 0 5px !important;
    }

    .ant-list-item-meta {
      padding: 8px 0px;
    }

    .ant-list-item-meta-avatar {
      height: 100% !important;

      svg {
        fill: map-get($color, action);
        font-size: size(22px) !important;
      }
    }

    .ant-list-item-meta-title {
      line-height: 1 !important;
      font-weight: bold !important;
      font-size: size(14px) !important;
      margin: 0px !important;
    }

    .ant-list-item-meta-description {
      margin-top: 5px !important;
      font-size: size(12px) !important;
      line-height: 1.3 !important;
      white-space: nowrap;
      text-overflow: ellipsis !important;
    }

    &__line {
      display: flex;
      flex-direction: column;

      &__ex-icon {
        transform: rotate(-90deg);
        transform-origin: center center;
        margin-right: 5px;
      }

      &__ex-container {
        overflow: hidden;
        max-height: 0px;

        &:first-of-type {
          // max-height: 100%;
        }

        &__description {
          padding: 10px 5px;
        }
      }
    }
  }
}
