@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
