@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/placeholder.scss';

.statistic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__contain {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-statistic-title {
      font-size: myClamp(13, 14, 1px);
      font-weight: 600;
      text-align: center;
    }

    .ant-statistic-content {
      width: 100% !important;
      margin: myClamp(9, 10, 1px) 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-statistic-content-value {
        font-size: myClamp(20, 26, 1px);
      }

      .ant-statistic-content-prefix,
      .ant-statistic-content-suffix {
        font-size: myClamp(18, 24, 1px);
      }
    }
  }

  &__explain {
    font-size: myClamp(12, 14, 1px);
    font-weight: 600;
  }
}
