@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.graph {
  height: 100%;
  width: 100% !important;
  overflow: hidden;

  canvas {
    width: 100% !important;
  }
}
