@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';
@import '../../sass/utils/mixin.scss';

.diagramme {
  width: 100% !important;

  canvas {
    width: 100% !important;
  }
}
