@import '../utils/variable.scss';

body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background: linear-gradient(
      90deg,
      rgba(255, 0, 179, 0.06) 1%,
      rgba(0, 213, 255, 0.3) 100%
    ),
    map-get($color, primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, sans-serif;
}

span,
p,
a,
li,
label,
button {
  font-family: -apple-system, sans-serif;
}
