@import '../../sass/utils/variable.scss';
@import '../../sass/utils/function.scss';

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
